import React, { useEffect, useState } from "react";
import { Container, Row, Table } from "react-bootstrap";
import "../../assets/css/style.css";
import "./Dashboard.css";
import { Card, Modal } from "antd";
import { FilterData } from "../../actions/Dashboard/Dashboard";
import { useAppSelector } from "../../hooks/hooks";
import { RootState } from "../../store/store";
import Password from "./Password/Password";

function Dashboard() {
  const [data, setdata] = useState({
    totalPopulation: 0,
    activeUsers: 0,
    group_a1: 0,
    group_a2: 0,
    group_b: 0,
    group_c: 0,
    total_refused: 0,
    total_scheduled: 0,
    total_scheduled_A12: 0,
    awv_completed_A12: 0,
    awv_completed_A12_per: 0,
    awv_completed_population_per: 0,
    awv_completed_total: 0,
    awv_pending_A12: 0,
    awv_pending_A12_per: 0,
    awv_pending_population: 0,
    awv_pending_population_per: 0,
  });
  const [loading, setloading] = useState(false);
  const [totalClinics, settotalClinics] = useState<string>("");
  const [totalInsurances, settotalInsurances] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { doctor_id, program_id, clinic_id, insurance_id } = useAppSelector(
    (state: RootState) => state.DashboardFilters
  );

  useEffect(() => {
    setloading(true);
    FilterData(doctor_id, program_id, clinic_id, insurance_id).then(
      ({ data: response }) => {
        const { data } = response;
        setdata(data);
        setloading(false);
        settotalClinics(response.total_clinics);
        settotalInsurances(response.total_insurances);
      }
    );
  }, [doctor_id, program_id, clinic_id, insurance_id]);
  const roleId = localStorage.getItem("role_id");
  const UpdatePassword = localStorage.getItem("password_update");
  useEffect(() => {
    if (UpdatePassword === "0") {
      setIsModalOpen(true);
    }
  }, [UpdatePassword]);

  return (
    <Container fluid className="mt-4">
      <Modal
        title="Change Password"
        maskClosable={false}
        closable={false}
        open={isModalOpen}
        footer={false}
      >
        <Password setIsOpenModal={setIsModalOpen} />
      </Modal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-xl-12">
            <Card
              loading={loading}
              className="cards mb-4"
              style={{ minHeight: "70px" }}
            >
              <div className="row  align-items-center">
                <div className="col-5 text-left ">
                  <div className="card-block p-0">
                    <h5 className=" m-0  text-right mt-3">Total Population</h5>
                  </div>
                </div>
                <div className="col-1 text-center ">
                  <div className="card-block p-0">
                    <i className="fa fa-users foricon text-success  mt-3" />
                  </div>
                </div>
                <div className="col-5 ">
                  <div className="card-block p-0">
                    <h5 className="m-0  text-left  mt-3">
                      <a className="text-secondary link ml-4  ">
                        {data?.totalPopulation}
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6">
            <Card
              loading={loading}
              className=" mb-0 card bg-c-green foruse order-card mb-3 "
              style={{ minHeight: "144px" }}
            >
              <div className="row  ">
                <div className="col-4 text-left ">
                  <div className="card-block ">
                    <h5 className="m-0 text-nowrap">Active Patients</h5>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className="card-block">
                    <i
                      className="fa fa-user fa-lg foricon text-primary   "
                      aria-hidden="true"
                    />
                  </div>
                </div>

                <div className="col-4 ">
                  <div className="card-block">
                    <h5 className="m-0 text-nowrap text-right ">
                      {data?.activeUsers}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 ">
                  <div className="card-block">
                    <h6 className="m-0">
                      <b>Group A1</b>
                      <span className="font-weight-bold ml-4 ">
                        {data?.group_a1}
                      </span>
                    </h6>
                    <h6 className="text-secondary mt-1">
                      Last seen 6 months ago
                    </h6>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="card-block">
                    <h6 className="m-0">
                      <b>Group A2</b>
                      <span className="font-weight-bold ml-4 ">
                        {data?.group_a2}
                      </span>
                    </h6>
                    <h6 className="text-secondary mt-1">
                      Last seen 6 - 12 months ago
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-md-12 col-xl-3">
            <Card
              loading={loading}
              className="card mb-3"
              style={{ minHeight: "144px" }}
            >
              <div className="row">
                <div className="col-5 text-center">
                  <div className="card-block">
                    <h5 className="text-left m-0">Group B</h5>
                  </div>
                </div>
                <div className="col-2 ">
                  <i
                    className="fa fa-user-times text-info ml-2 mt-2"
                    aria-hidden="true"
                    style={{ fontSize: "35px" }}
                  />
                </div>
                <div className="col-5 text-center">
                  <div className="card-block">
                    <h5 className="f-right">{data?.group_b}</h5>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 ">
                  <div className="card-block p-2">
                    <h6 className="text-secondary pt-3">
                      Bad Patients PCP Changed / Deceased / Hospice / Relocate
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-12 col-xl-3">
            <Card
              loading={loading}
              className="card bg-c-pink order-card mb-3"
              style={{ minHeight: "144px" }}
            >
              <div className="row">
                <div className="col-5 text-center">
                  <div className="card-block">
                    <h5 className="text-left m-0">Group C</h5>
                  </div>
                </div>
                <div className="col-2 ">
                  <i
                    className="fa fa-user-minus text-danger ml-2 mt-2"
                    aria-hidden="true"
                    style={{ fontSize: "35px" }}
                  />
                </div>
                <div className="col-5 text-center">
                  <div className="card-block">
                    <h5 className="f-right">{data?.group_c}</h5>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 ">
                  <div className="card-block p-2">
                    <h6 className="text-secondary pt-3">
                      Contact Not seen in last 12 months
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <Row>
          <div className="col-md-12 col-xl-8">
            <Card loading={loading} className="card-stats dashboardCard">
              <Table hover className="text-dark">
                <thead>
                  <tr>
                    <th>Program</th>
                    <th>Members</th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingTop: 0 }}>
                      Annual Wellness Completed Total
                    </td>
                    <td>{data?.awv_completed_total}</td>
                    <td>{data?.awv_completed_population_per.toFixed(2)}%</td>
                  </tr>
                  <tr>
                    <td>Annual Wellness Completed From Group A1 & Group A2</td>
                    <td>{data?.awv_completed_A12}</td>
                    <td>{data?.awv_completed_A12_per.toFixed(2)}%</td>
                  </tr>
                  <tr>
                    <td>Annual Wellness Incomplete From Group A1 & Group A2</td>
                    <td>{data?.awv_pending_A12}</td>
                    <td>{data?.awv_pending_A12_per.toFixed(2)}%</td>
                  </tr>
                  <tr>
                    <td> Annual Wellness Incomplete From Population </td>
                    <td>{data?.awv_pending_population}</td>
                    <td>{data?.awv_pending_population_per.toFixed(2)}%</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
          <div className="col-md-12 col-xl-4">
            <Card loading={loading} className="card-stats dashboardCard">
              <Table hover className="text-dark">
                <thead>
                  <tr>
                    <th>Scheduled</th>
                    <th>Member</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Scheduled from Group A1 & A2</td>
                    <td>{data?.total_scheduled_A12}</td>
                  </tr>
                  <tr>
                    <td>Total Scheduled from Total Poplulation</td>
                    <td>{data?.total_scheduled}</td>
                  </tr>
                  <tr>
                    <td>Total Refused</td>
                    <td>{data?.total_refused}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </div>
      <br />
      {roleId === "1" || roleId === "13" ? (
        <div className="container-fluid">
          <Row>
            <div className="col-md-12 col-xl-6">
              <Card loading={loading} className="card-stats dashboardCard p-3">
                <div className="row">
                  <div className="col-5 text-center">
                    <h5 className="text-left m-0">No of Clinics</h5>
                  </div>
                  <div className="col-2 ">
                    <i
                      className="fas fa-hospital-user text-secondary ml-2"
                      aria-hidden="true"
                      style={{ fontSize: "35px" }}
                    />
                  </div>
                  <div className="col-5 text-center">
                    <h5 className="f-right">{totalClinics}</h5>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-12 col-xl-6">
              <Card loading={loading} className="card-stats dashboardCard p-3">
                <div className="row">
                  <div className="col-5 text-center">
                    <h5 className="text-left m-0">No of Insurances</h5>
                  </div>
                  <div className="col-2 ">
                    <i
                      className="fas fa-hospital text-warning ml-2"
                      aria-hidden="true"
                      style={{ fontSize: "35px" }}
                    />
                  </div>
                  <div className="col-5 text-center">
                    <h5 className="f-right">{totalInsurances}</h5>
                  </div>
                </div>
              </Card>
            </div>
          </Row>
        </div>
      ) : null}
    </Container>
  );
}

export default Dashboard;
