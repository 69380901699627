/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import moment from "moment";
import "react-dyn-tabs/style/react-dyn-tabs.min.css";
import "react-dyn-tabs/themes/react-dyn-tabs-card.min.css";
import "assets/css/questions_answers.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Pagination, Input, Form, Switch } from "antd";
import { Dropdown } from "react-bootstrap";
import { PatientType, familyprops, Doctors } from "@/Types/PatientType";
import {
  getPatientList,
  addNewPatient,
  updatePatient,
  deletePatient,
  searchPatient,
  roleFilter,
  patientBulkAdd,
  patientBulkAssign,
} from "../../../actions/Patients/PatientActions";
import PatientForm from "./PatientForm";
import { OpenNotification } from "../../../Utilties/Utilties";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "../../../store/store";
import { useAppSelector } from "../../../hooks/hooks";
import AddBulkPatient from "./AddBulkPatient/AddBulkPatient";
import * as XLSX from "xlsx";
import { message } from "antd";
import AssignBulkPatient from "./AssignBulkPatient/AssignBulkPatient";

function Patients({ openTab }: { openTab: any }) {
  const [patient, setPatient] = useState<PatientType>({} as PatientType);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isBulkAssign, setIsBulkAssign] = useState<boolean>(false);
  const [isBulkOpen, setIsBulkOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [data, setData] = React.useState<any[]>([]);
  const [loading, settLoading] = useState<boolean>(false);
  const [program, setProgram] = React.useState<any[]>([]);
  const [Newdata, setNewdata] = React.useState<any[]>([]);
  const [coordinators, setCoordinators] = React.useState<any[]>([]);
  const [insurances, setInsurances] = React.useState<any>([]);
  const [insurancesCLinic, setInsurancesClinic] = React.useState<any>([]);
  const [doctors, setDoctors] = React.useState<Doctors[]>([]);
  const [doctorsClinic, setDoctorsClinic] = React.useState<Doctors[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [myPatients, setMyPatients] = useState<any>(null);
  const [bulkAssign, setBulkAssign] = useState<any>(null);
  const [loadingClc, setloadingclc] = useState<any>(false);
  const [disable, setdisable] = useState<any>(false);
  const [clinics, setClinics] = useState<any>([]);
  const [family_history, setFamily_history] = useState<familyprops[]>([]);
  const [bulkData, setBulkData] = useState<any>([]);
  const [clinicIds, setClinicIds] = useState<any>(null);
  const [errors, setError] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const [loaderModalButton, setloaderModalButton] = useState(false);
  const { Search } = Input;
  const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

  /* Added clinicId as constant to call the api on clinic switch */
  const { clinicId } = useAppSelector(
    (state: RootState) => state.clinicReducer
  );

  const roleId = localStorage.getItem("role_id");

  useEffect(() => {
    settLoading(true);
    fetchAllPatients();
  }, [currentPage, Newdata, clinicId, myPatients, bulkAssign]);

  const fetchAllPatients = () => {
    getPatientList(search, currentPage, myPatients, bulkAssign)
      .then(({ data: response }) => {
        settLoading(false);
        if (response.success) {
          setCoordinators(response.coordinators);
          setData(response.data);
          setInsurances(response.insurances);
          setDoctors(response.doctors);
          setTotalRecords(response.total_records);
          setClinics(response.clinic_list);
          setProgram(response.programs_list);
        } else {
          OpenNotification("error", response.message);
        }
      })
      .catch((err) => {
        console.log("error is ", err);
        settLoading(false);
      });
  };

  const handleChange = (e: any) => {
    const value = e.target.value.toUpperCase();

    setPatient({
      ...patient,
      [e.target.name]: value,
    });
  };
  const handleCoordinatorChange = (e: any) => {
    const value = e;
    console.log(value);

    setPatient({
      ...patient,
      ["coordinator_id"]: value,
    });
  };

  const handleClinicChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    setloadingclc(true);
    roleFilter(value).then(({ data: response }) => {
      setloadingclc(false);
      setDoctorsClinic(response.doctors);
      setInsurancesClinic(response.insurances);
      setPatient({
        ...patient,
        [e.target.name]: value,
      });
    });
  };
  const handlecellChange = (e: any) => {
    const x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2]
      ? x[1]
      : +x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    const value = e.target.value;
    setPatient({
      ...patient,
      [e.target.name]: value,
    });
  };
  const handlepatientInfo = (e: any) => {
    const value = e.target.value.toUpperCase();
    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setPatient({
        ...patient,
        [e.target.name]: value,
      });
    }
  };
  const handlezipChange = (e: any) => {
    const value = e.target.value;
    const re = /^[0-9 ]+$/;
    if (value === "" || re.test(value)) {
      setPatient({
        ...patient,
        [e.target.name]: value,
      });
    }
  };
  const handleaddress = (e: any) => {
    const value = e.target.value.toUpperCase();
    const re = /^[A-Za-z0-9 ]+$/;
    if (value === "" || re.test(value)) {
      setPatient({
        ...patient,
        [e.target.name]: value,
      });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    settLoading(true);

    e.preventDefault();
    if (patient.id) {
      const row = {
        ...patient,
        family_history,
      };
      settLoading(true);

      updatePatient(patient.id, row)
        .then(({ data: response }) => {
          settLoading(false);
          const newdata = [...data];
          const index = data.findIndex((item) => item.id === patient.id);

          newdata[index] = response.data;
          setNewdata(newdata);

          if (response.success) {
            OpenNotification("success", response.message);

            setIsOpen(false);
            setFamily_history([]);
          } else {
            settLoading(false);
            OpenNotification("error", "Please fill all the required fields");

            alert("Please fill all input data");
          }
        })
        .catch((err) => {
          OpenNotification("error", err.message);
        });
    } else {
      const row = {
        ...patient,
        family_history,
      };

      addNewPatient(row).then(({ data: response }) => {
        settLoading(false);

        if (response.success) {
          setNewdata([...data, response.data]);
          setIsOpen(false);
          OpenNotification("success", response.message);
          setFamily_history([]);
        } else {
          OpenNotification("error", "Please fill all the required fields");
          alert("Please fill all input data");
        }
      });
    }
  };

  const deletepatientRecord = (id: any) => {
    settLoading(true);
    deletePatient(id)
      .then(({ data: response }) => {
        settLoading(false);
        if (response.success) {
          const list = data.filter((item) => item.id !== id);
          setData(list);
          OpenNotification("success", response.message);
        } else {
          settLoading(false);
          OpenNotification("error", response.message);
          alert("error deleting record");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const error = (id: any) => {
    Modal.error({
      title: "Are you sure to delete",
      closable: true,
      okText: "Yes",
      onOk() {
        deletepatientRecord(id);
      },
    });
  };

  const columns = [
    {
      title: "Id",
      key: "index",
      render: (text: string, record: any, index: number) =>
        (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Account",
      dataIndex: "identity",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <>
          <a onClick={() => openTab(record, program, coordinators)}>
            {" "}
            <span className="text-uppercase">{record.name}</span>
          </a>
        </>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact_no",
      render: (text: string) =>
        text?.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    },

    {
      title: "DOB",
      dataIndex: "dob",
      render: (text: any, record: any) =>
        moment(record.dob).format("MM/DD/YYYY"),
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Insurance",
      dataIndex: "insurance_name",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Action",
      dataIndex: "btn",
      render: (_: any, record: { id: React.SetStateAction<null> }) =>
        data.length >= 1 ? (
          <Dropdown>
            <Dropdown.Toggle variant="info" size="sm" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: 0 }}>
              {/*  <Dropdown.Item>
                <Popconfirm
                  title="Sure to delete?"

                  onConfirm={() => deletepatientRecord(record.id)}
                >
                  Delete
                </Popconfirm>
              </Dropdown.Item> */}
              <Dropdown.Item
                className="m-0"
                onClick={
                  () =>
                    error(record.id) /* () => deletepatientRecord(record.id) */
                }
              >
                Delete
              </Dropdown.Item>
              <Dropdown.Item
                className="mr-3 cursor-pointer"
                onClick={() => handleEdit(record.id, record)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="mr-3 cursor-pointer"
                onClick={() => openTab(record, program, coordinators)}
              >
                Open Profile
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null,
    },
  ];

  const handleCloseModel = () => {
    setIsOpen(false);
    setFamily_history([]);
  };

  const handleFormOpen = () => {
    setTitle("Add patient");
    setIsOpen(true);
    setPatient({} as PatientType);
  };
  const handleBulkAssign = () => {
    setTitle("Assign Bulk patient");
    setBulkAssign(1);
    setIsBulkAssign(true);
  };
  const handleBulkOpen = () => {
    setTitle("Add Bulk patient");
    setBulkData([]);
    setClinicIds(null);
    setIsBulkOpen(true);
    setError(false);
  };
  const setBulkOpen = () => {
    setTitle("Add Bulk patient");
    setIsBulkOpen(false);
  };
  const setAssignBulkOpen = () => {
    setTitle("Add Bulk patient");
    setBulkAssign(null);
    setIsBulkAssign(false);
    setSelectedRowData([]);
  };
  const handleOkBulkAssign = () => {
    setTitle("Add Bulk patient");
    const payload = {
      patient_ids: selectedRowData,
      coordinator_id: patient.coordinator_id,
    };
    patientBulkAssign(payload).then(({ data: response }) => {
      if (response.success) {
        setBulkAssign(null);
        setBulkAssign(1);
        fetchAllPatients();
        OpenNotification("success", response.message);
      }
    });
  };

  const handleEdit = (id: any, data: any) => {
    if (data.clinic_id) {
      setloadingclc(true);
      roleFilter(data.clinic_id).then(({ data: response }) => {
        setloadingclc(false);
        setDoctorsClinic(response.doctors);
        setInsurancesClinic(response.insurances);
      });
    }
    const insurance = Object.keys(insurances).find(
      (key) => insurances[key] === data.insurance_name
    );
    setTitle("Update patient");
    setIsOpen(true);
    data = { ...data, insurance };
    setdisable(true);
    setPatient(data);
    setFamily_history(data.family_history);
  };

  /* Calculating patients age from date */
  const onChange = (name: any, value: any) => {
    const years = moment().diff(value, "years");
    setPatient({
      ...patient,
      [name]: value,
      age: years,
    });
  };

  /* Search Patient */
  const onSearch = (value: any) => {
    settLoading(true);
    setSearch(value);
    if (isBulkAssign) {
      setBulkAssign(1);
      searchPatient(value, bulkAssign).then(({ data: response }) => {
        settLoading(false);
        setData(response.data);
        setTotalRecords(response.total_records);
      });
    } else {
      searchPatient(value).then(({ data: response }) => {
        settLoading(false);
        setData(response.data);
        setTotalRecords(response.total_records);
      });
    }
  };
  const handleFileChange = (info: any) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      try {
        const { result } = e.target as any;
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the first sheet is the one to convert
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = jsonData[0] as string[];
        const rows = jsonData.slice(1);
        const headerMappings: { [key: string]: string } = {
          middle_name: "mid_name",
          patient_full_name: "name",
          address_line_1: "address",
          zip_code: "zipCode",
          phone: "contact_no",
          clinic: "clinic_id",
          primary_care_physician: "doctor_id",
          insurance: "insurance_id",
          dob: "dob", // Assuming "dob" column header
        };
        const jsonDataWithHeaders = rows.map((row: any) => {
          const obj: { [key: string]: any } = {};
          headers.forEach((header, index) => {
            let customHeader = header.replace(/\s/g, "_").toLowerCase();
            // eslint-disable-next-line no-prototype-builtins
            if (headerMappings.hasOwnProperty(customHeader)) {
              customHeader = headerMappings[customHeader];
            }
            if (customHeader === "dob") {
              const serialDate = row[index];
              const dateValue = XLSX.SSF.parse_date_code(serialDate);
              const dob = new Date(
                Date.UTC(dateValue.y, dateValue.m - 1, dateValue.d)
              );
              const formattedDate = dob.toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              });
              obj[customHeader] = formattedDate;
            } else {
              obj[customHeader] = row[index];
            }
          });
          return obj;
        });
        setBulkData(jsonDataWithHeaders);
      } catch (error) {
        message.error("Please upload a valid excel file");
      }
    };

    const fileList = info.fileList.slice(-1);

    if (fileList.length > 0) {
      fileReader.readAsArrayBuffer(fileList[0].originFileObj);
    } else {
      setBulkData([]);
    }
  };
  const handleClinicIdChange = (value: number) => {
    setClinicIds(value);
  };
  const handleBulkSubmit = () => {
    setloaderModalButton(true);
    if (!clinicIds) {
      setError(true);
      return false;
    }
    if (clinicIds) {
      setError(false);
      const payload = {
        clinicIds,
        data: bulkData,
      };
      patientBulkAdd(payload).then(({ data: response }) => {
        if (response.success === true) {
          fetchAllPatients();
          setloaderModalButton(false);
          setIsBulkOpen(false);
          OpenNotification("success", "Bulk patient added successfully");
        }
      });
    }
  };

  function handleRowSelectionChange(e: any) {
    setMyPatients(e === true ? 1 : 0);
  }

  return (
    <>
      <div
        className="container "
        style={{
          margin: "",
          background: "white",
          padding: "10px",
          borderRadius: "7px",
        }}
      >
        <div style={{ width: "100%" }}>
          <div className="row">
            <div className="col-4 sm-12 ">
              <h2>Patients</h2>
            </div>

            <div className="col-8 sm-12 ">
              {roleId === "1" || roleId === "13" || roleId === "11" ? (
                <button
                  className="btn btn-primary text-light float-right mr-2"
                  style={{ fontSize: "12px" }}
                  onClick={() => handleBulkAssign()}
                >
                  Bulk Assign
                </button>
              ) : null}
              {roleId === "1" || roleId === "13" || roleId === "11" ? (
                <button
                  className="btn btn-success float-right text-light mr-2"
                  style={{ fontSize: "12px" }}
                  onClick={() => handleBulkOpen()}
                >
                  Add Bulk patient
                </button>
              ) : null}
              <button
                className="btn btn-info float-right mr-2"
                style={{ fontSize: "12px" }}
                onClick={() => handleFormOpen()}
              >
                Add New
              </button>
              <Search
                placeholder="Search"
                className="float-right mr-2"
                onSearch={onSearch}
                enterButton
                style={{ width: "auto" }}
              />
              {roleId === "23" ? (
                <Form>
                  <Form.Item label="My patients" className="float-right mr-2">
                    <Switch onChange={(e) => handleRowSelectionChange(e)} />
                  </Form.Item>
                </Form>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            pagination={false}
            loading={{ spinning: loading, indicator: antIcon }}
          />
          <br />
          <Pagination
            total={totalRecords}
            current={currentPage}
            pageSize={10}
            showSizeChanger={false}
            onChange={(page: number) => setCurrentPage(page)}
          />
          <br />
        </div>
      </div>
      <PatientForm
        isOpen={isOpen}
        handleCloseModel={handleCloseModel}
        title={title}
        patient={patient}
        loading={loading}
        doctors={doctors}
        coordinators={coordinators}
        handlepatientInfo={handlepatientInfo}
        handledateChanges={onChange}
        handlecellChange={handlecellChange}
        handleaddress={handleaddress}
        handlezipChange={handlezipChange}
        insurances={insurances}
        doctorsClinic={doctorsClinic}
        insurancesCLinic={insurancesCLinic}
        handleChange={handleChange}
        handleClinicChange={handleClinicChange}
        handleSubmit={handleSubmit}
        clinicList={clinics}
        disable={disable}
        loadingClc={loadingClc}
      />
      <AddBulkPatient
        title={title}
        bulkData={bulkData}
        clinics={clinics}
        IsOpenBulk={isBulkOpen}
        loader={loaderModalButton}
        handleFileChange={handleFileChange}
        setBulkOpen={setBulkOpen}
        handleBulkSubmit={handleBulkSubmit}
        handleClinicIdChange={handleClinicIdChange}
        errors={errors}
      />
      <AssignBulkPatient
        title={title}
        isBulkAssign={isBulkAssign}
        handleBulkAssign={handleBulkAssign}
        setAssignBulkOpen={setAssignBulkOpen}
        handleOkBulkAssign={handleOkBulkAssign}
        data={data}
        loading={loading}
        onSearch={onSearch}
        coordinators={coordinators}
        errors={errors}
        handleCoordinatorChange={handleCoordinatorChange}
        setSelectedRowData={setSelectedRowData}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
      />
    </>
  );
}

export default Patients;
