import { http } from "../../http";

// const getCcmBillables = async (data?: string, page?: number) => {
//   return await http.post(`questionaire/unsigned-encounters?page=${page}`, data);
// };

const storeTask = async (data: any) => {
  return await http.post("ccmtasks/store", data);
};

const getCoordinators = async (payload: any) => {
  return await http.post("ccmtasks/get-coordinators", payload);
};
const getLogs = async (payload:any) => {
  return await http.post("ccmtasks/get-logs", payload);
};

export { storeTask, getCoordinators, getLogs };
